import React from "react"
import styled from "styled-components"
import { graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import ContactCTA from '../components/ContactCTA'
import NewsletterCTA from '../components/NewsletterCTA'

const OpeningParagraph = styled.section`
  margin-bottom: 50px;

  p {
    color: var(--gray);
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
`

const ResourcesSection = styled.section`
  margin-bottom: 120px;

  .resources-grid {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 80px;

    .resource {
      position: relative;

      .resource-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 100%;
        height: 100%;
        padding: 18px 19px 18px 17px;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .gatsby-image-wrapper {
          margin-bottom: 35px;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            background-image: linear-gradient(225deg, #000000 0%, rgba(0,0,0,0.00) 47%, #000000 100%);
          }
        }
      }
    }
  }
`

const FeaturedSection = styled.section`
  margin-bottom: 120px;

  .row-two-col {
    align-items: center;

    p {
      margin-bottom: 35px;
      font-size: 20px;
      color: var(--gray);
    }

    .gatsby-image-wrapper {
      width: 100%;
      max-width: 450px;
      height: auto;
    }
  }
`

// markup
const ResourcesPage = (props) => {
  return (
    <main>
      {props.data.pageTitleData.edges.map(({ node }) => (
        <PageTitle key={node.id} title={node.pageTitle} paragraph={node.pageTitleParagraph.pageTitleParagraph} />
      ))}
      <OpeningParagraph>
        {props.data.resources.edges.map(({ node }) => (
          <div className="container" key={node.id}>
            <p>{node.openingParagraph.openingParagraph}</p>
          </div>
        ))}
      </OpeningParagraph>
      <ResourcesSection>
        <div className="container">
          {props.data.resources.edges.map(({ node }) => (
            <div className="resources-grid" key={node.id}>
              {node.newsUrl &&
                <div className="resource">
                  <StaticImage
                    src="../images/resources-frame.png"
                    width={360}
                    height={360}
                    alt=""
                  />
                  <div className="resource-content">
                    <GatsbyImage image={node.newsImage.gatsbyImageData} alt={node.newsImage.title} width={324} />
                    <Link to={node.newsUrl} className="btn btn-pink">{node.newsTitle}</Link>
                  </div>
                </div>
              }
              <div className="resource">
                <StaticImage
                  src="../images/resources-frame.png"
                  width={360}
                  height={360}
                  alt=""
                />
                <div className="resource-content">
                  <GatsbyImage image={node.blogImage.gatsbyImageData} alt={node.blogImage.title} width={324} />
                  <Link to={node.blogUrl} className="btn btn-pink">{node.blogTitle}</Link>
                </div>
              </div>
              <div className="resource">
                <StaticImage
                  src="../images/resources-frame.png"
                  width={360}
                  height={360}
                  alt=""
                />
                <div className="resource-content">
                  <GatsbyImage image={node.eventsImage.gatsbyImageData} alt={node.eventsImage.title} width={324} />
                  <Link to={node.eventsUrl} className="btn btn-pink">{node.eventsTitle}</Link>
                </div>
              </div>
              {node.faqsUrl &&
                <div className="resource">
                  <StaticImage
                    src="../images/resources-frame.png"
                    width={360}
                    height={360}
                    alt=""
                  />
                  <div className="resource-content">
                    <GatsbyImage image={node.faqsImage.gatsbyImageData} alt={node.faqsImage.title} width={324} />
                    <Link to={node.faqsUrl} className="btn btn-pink">{node.faqsTitle}</Link>
                  </div>
                </div>
              }
           </div>
          ))}
        </div>
        
      </ResourcesSection>
      <ContactCTA />
      <FeaturedSection>
        <div className="container">
          {props.data.resources.edges.map(({ node }) => (
            <div className="row-two-col" key={node.id}>
              <div className="col-1">
                <h2>{node.featuredResourceH2}</h2>
                <p>{node.featuredResourceParagraph.featuredResourceParagraph}</p>
                <Link to={node.featuredResourceButtonUrl} className="btn btn-pink">{node.featuredResourceButtonText}</Link>
              </div>
              <div className="col-2">
                <GatsbyImage image={node.featuredResourceImage.gatsbyImageData} alt={node.featuredResourceImage.title} />
              </div>
            </div>
          ))}
        </div>
      </FeaturedSection>
      <NewsletterCTA />
    </main>
  )
}

export default ResourcesPage

export const Head = (seo) => {
  const seoData = seo.data.pageTitleData
  return (
    <>
      {seoData.edges.map(({ node }) => (
      <Seo key={node.id} title={node.seoTitle} description={node.seoDescription.seoDescription} image={node.seoImage.url} />
      ))}
    </>
  )
}

export const NewsletterContent = graphql`
  {
    pageTitleData: allContentfulResourcesPage(
      filter: {pageTitle: {eq: "Resources"}}
    ) {
      edges {
        node {
          id
          pageTitle
          pageTitleParagraph {
            pageTitleParagraph
          }
          seoTitle
          seoDescription {
            seoDescription
          }
          seoImage {
            url
          }
        }
      }
    }
    resources: allContentfulResourcesPage(
      filter: {pageTitle: {eq: "Resources"}}
    ) {
      edges {
        node {
          id
          openingParagraph {
            openingParagraph
          }
          newsTitle
          newsUrl
          newsImage {
            gatsbyImageData(width: 600, placeholder: BLURRED)
            title
          }
          blogTitle
          blogUrl
          blogImage {
            gatsbyImageData(width: 600, placeholder: BLURRED)
            title
          }
          eventsTitle
          eventsUrl
          eventsImage {
            gatsbyImageData(width: 600, placeholder: BLURRED)
            title
          }
          faqsTitle
          faqsUrl
          faqsImage {
            gatsbyImageData(width: 600, placeholder: BLURRED)
            title
          }
          featuredResourceH2
          featuredResourceParagraph {
            featuredResourceParagraph
          }
          featuredResourceButtonText
          featuredResourceButtonUrl
          featuredResourceImage {
            gatsbyImageData(width: 850, placeholder: BLURRED)
            title
          }
        }
      }
    }
  }
`;
